import { useQuery } from '@apollo/client';
import { PrintOutlined } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconeLogo } from '../../components/icons';
import Input from '../../components/input/input';
import Toolbar from '../../components/toolbar/toolbar';
import { FIND_ANAMNESE_BY_ID } from '../../graphql/queries';
import Data from '../../utils/data';
import { getImageUrl } from '../../utils/file';
import Number from '../../utils/number';
import String from '../../utils/string';

export default function AnamneseConsulta() {
  const componentRef = useRef();
  const {anamneseId} = useParams();

  const [grupos, setGrupos] = useState([]);
  const [formData, setFormData] = useState({});
  const [anamnese, setAnamnese] = useState({});
  const [respostas, setRespostas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [anamneseCopy, setAnamneseCopy] = useState({});

  const anamneseQuery = useQuery(FIND_ANAMNESE_BY_ID, {
    variables: {
      id: anamneseId,
    },
  });

  useEffect(() => {
    if (anamneseQuery.loading) return;

    const data = anamneseQuery.data?.anamnese;

    setAnamnese(data);
    setRespostas(data?.respostas || []);
    const gruposData = data?.respostas
      .map((objeto) => objeto.modeloPergunta?.pergunta?.modeloGrupo)
      .filter(
        (grupo, index, self) =>
          index === self.findIndex((g) => g.id === grupo.id),
      )
      .sort((grupo1, grupo2) => grupo1.ordem - grupo2.ordem)
      .map((grupo) => ({value: grupo.id, label: grupo.nome}));

    setGrupos(gruposData || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anamneseQuery]);

  useEffect(() => {
    if (!anamnese.id) return;

    const respostas = {};
    anamnese.respostas?.forEach((r) => {
      respostas[r.id] = r.valorResposta;
    });
    setFormData(respostas);
  }, [anamnese]);

  const getRespostaComplexa = (append, idPergunta) => {
    return `<w:color w:val="${getCorResposta(idPergunta)}"/>
            <w:t>${append}${getResposta(idPergunta)}</w:t>`;
  };

  const getResposta = (idPergunta) => {
    const pergunta = anamneseCopy.respostas.find(
      (e) => e.modeloPergunta.pergunta.id === idPergunta,
    );

    if (anamneseCopy.respostas.indexOf(pergunta) !== -1)
      anamneseCopy.respostas.splice(
        anamneseCopy.respostas.indexOf(pergunta),
        1,
      );

    let resposta = pergunta?.valorResposta ?? '';

    if (pergunta?.modeloPergunta.pergunta.tipo === 'COMBOBOX') {
      return pergunta.modeloPergunta.pergunta.valoresReferencia.filter(
        (v) => v.id === resposta,
      )?.[0]?.nome;
    }

    return resposta;
  };

  const getCorResposta = (idPergunta) => {
    const pergunta = anamneseCopy.respostas.find(
      (e) => e.modeloPergunta.pergunta.id === idPergunta,
    );

    return pergunta?.modeloPergunta.pergunta.valoresReferencia
      .filter((v) => v.id === pergunta?.valorResposta)?.[0]
      ?.corHex.split('#')?.[1];
  };

  const handleConvert = async () => {
    if (isLoading) return;

    setIsLoading(true);

    const copy = JSON.parse(JSON.stringify(anamnese));

    copy.respostas.forEach((r) => {
      r.anexoId = getImageUrl(r.anexoId);
    });

    setAnamneseCopy(copy);

    try {
      // Fetch the .docx file from assets
      const response = await (await fetch('/assets/modelo.docx')).blob();

      const peso = Number.safeParseFloat(
        getResposta('647023ce-144e-11ef-9914-02ea7f69b323') ?? 0,
      );

      const cintura = Number.safeParseFloat(
        getResposta('693aff26-145e-11ef-9914-02ea7f69b323') ?? 0,
      );
      const quadril = Number.safeParseFloat(
        getResposta('6048f525-145e-11ef-9914-02ea7f69b323') ?? 0,
      );

      // Calcular y_balance_1
      const y_balance_1 = Math.ceil(
        (Number.safeParseFloat(
          getResposta('63a2b26e-69f7-11ef-9dd0-02bc37d68b7b'),
        ) +
          Number.safeParseFloat(
            getResposta('89e43ee0-69f7-11ef-9dd0-02bc37d68b7b'),
          ) +
          Number.safeParseFloat(
            getResposta('9a9bb9c2-69f7-11ef-9dd0-02bc37d68b7b'),
          )) /
          3,
      );

      // Calcular y_balance_2
      const y_balance_2 = Math.ceil(
        (Number.safeParseFloat(
          getResposta('6cf0bf1f-69f7-11ef-9dd0-02bc37d68b7b'),
        ) +
          Number.safeParseFloat(
            getResposta('91d15c01-69f7-11ef-9dd0-02bc37d68b7b'),
          ) +
          Number.safeParseFloat(
            getResposta('a66ec3a3-69f7-11ef-9dd0-02bc37d68b7b'),
          )) /
          3,
      );

      // Calcular y_balance_3 (diferença em porcentagem entre y_balance_1 e y_balance_2)
      const y_balance_3 =
        y_balance_1 > y_balance_2
          ? y_balance_1 - y_balance_2
          : y_balance_2 - y_balance_1;

      const formData = new FormData();
      formData.append('file', response, 'output.docx');
      formData.append(
        'json',
        JSON.stringify({
          isF: anamnese.atleta?.sexo === 'FEMININO' ? 'X' : '',
          isM: anamnese.atleta?.sexo === 'MASCULINO' ? 'X' : '',
          nome: anamnese.atleta?.nome.split(' ')[0],
          idade: `${Data.calcularIdade(
            anamnese.atleta?.dataNascimento,
          )} ANOS (YRS ${Data.dataFormat(anamnese.atleta?.dataNascimento)})`,
          nomeCAPS: anamnese.atleta?.nome.toUpperCase(),
          data_avaliacao: Data.dataFormat(anamnese.dataHoraLancamento),
          qualidade_da_reacao: getResposta(
            'af0e1b82-6942-11ef-9dd0-02bc37d68b7b',
          ),
          qualidade_da_tomada_de_decisao: getResposta(
            'f9ec36f6-6942-11ef-9dd0-02bc37d68b7b',
          ),
          atencao: getResposta('2224ff8a-6943-11ef-9dd0-02bc37d68b7b'),
          controle_da_impulsividade: getResposta(
            '608be1de-6943-11ef-9dd0-02bc37d68b7b',
          ),
          performance_c: getResposta('b3f2dcf9-6705-11ef-9dd0-02bc37d68b7b'),
          observacao_1: getResposta('75a49402-6943-11ef-9dd0-02bc37d68b7b'),
          peso: Number.format(peso),
          altura: Number.format(
            getResposta('7ebb7c0f-1450-11ef-9914-02ea7f69b323'),
          ),
          per_g: Number.format(
            getResposta('1c1d2246-145d-11ef-9914-02ea7f69b323'),
          ),
          imc: Number.format(
            getResposta('e27c0563-145c-11ef-9914-02ea7f69b323'),
          ),
          rcq: new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(cintura / quadril),
          ml_ml: `${Math.ceil(peso * 35)} ML / ${Math.ceil(peso * 40)} ML`,
          observacao_2: getResposta('b3b1a287-69fd-11ef-9dd0-02bc37d68b7b'),
          sld: getResposta('515a7488-16bc-11ef-baff-02ea7f69b323'),
          sle: getResposta('7264b50c-16bc-11ef-baff-02ea7f69b323'),
          aid: getResposta('45868388-16c0-11ef-baff-02ea7f69b323') - 90,
          aie: getResposta('61f9fddc-16c0-11ef-baff-02ea7f69b323') - 90,
          rqd: getResposta('8363ac10-16ba-11ef-baff-02ea7f69b323'),
          rqe: getResposta('b8802a34-16ba-11ef-baff-02ea7f69b323'),
          ttd: getRespostaComplexa(
            'D - ',
            '91d3bce4-6944-11ef-9dd0-02bc37d68b7b',
          ),
          tte: getRespostaComplexa(
            'E - ',
            '03572fa8-6945-11ef-9dd0-02bc37d68b7b',
          ),
          smd: getRespostaComplexa(
            'D - ',
            '10d67664-16ca-11ef-baff-02ea7f69b323',
          ),
          sme: getRespostaComplexa(
            'E - ',
            '37b88898-16ca-11ef-baff-02ea7f69b323',
          ),
          add: getResposta('91bc6d34-16c8-11ef-baff-02ea7f69b323'),
          ade: getResposta('c54ca708-16c8-11ef-baff-02ea7f69b323'),
          patrick: getRespostaComplexa(
            '',
            'f5f5de5b-16b4-11ef-baff-02ea7f69b323',
          ),
          observacao_3: getResposta('d45a3b09-69fd-11ef-9dd0-02bc37d68b7b'),
          observacao_4: getResposta('ab8f1d86-69fd-11ef-9dd0-02bc37d68b7b'),
          fadir: getRespostaComplexa(
            '',
            '80b0927c-6945-11ef-9dd0-02bc37d68b7b',
          ),
          thompson: getRespostaComplexa(
            '',
            'c7bcbe61-6945-11ef-9dd0-02bc37d68b7b',
          ),
          djurdan: getRespostaComplexa(
            'D - ',
            '5cc53810-16c2-11ef-baff-02ea7f69b323',
          ),
          ejurdan: getRespostaComplexa(
            'E - ',
            'ac25e69c-69fa-11ef-9dd0-02bc37d68b7b',
          ),
          squezze: getRespostaComplexa(
            '',
            '41676ac3-16b6-11ef-baff-02ea7f69b323',
          ),
          dbridge: getRespostaComplexa(
            'D - ',
            '321838c6-6946-11ef-9dd0-02bc37d68b7b',
          ),
          ebridge: getRespostaComplexa(
            'E - ',
            '4c57bc1a-6946-11ef-9dd0-02bc37d68b7b',
          ),
          y_balance_1,
          y_balance_2,
          y_balance_3,
          y_balance_4: getResposta('63a2b26e-69f7-11ef-9dd0-02bc37d68b7b'),
          y_balance_5: getResposta('89e43ee0-69f7-11ef-9dd0-02bc37d68b7b'),
          y_balance_6: getResposta('9a9bb9c2-69f7-11ef-9dd0-02bc37d68b7b'),
          y_balance_7: getResposta('6cf0bf1f-69f7-11ef-9dd0-02bc37d68b7b'),
          y_balance_8: getResposta('91d15c01-69f7-11ef-9dd0-02bc37d68b7b'),
          y_balance_9: getResposta('a66ec3a3-69f7-11ef-9dd0-02bc37d68b7b'),
          squat_1:
            Number.format(getResposta('62aad7d6-16e5-11ef-baff-02ea7f69b323')) +
            ' CM',
          squat_2:
            Number.format(getResposta('27ce2192-69fc-11ef-9dd0-02bc37d68b7b')) +
            ' m/s',
          bridge_1:
            getResposta('b20eb64c-16cd-11ef-baff-02ea7f69b323') + ' REP',
          bridge_2:
            getResposta('de313c30-16cd-11ef-baff-02ea7f69b323') + ' REP',
          foto: getImageUrl(anamnese.atleta?.fotoPerfil),
          observacao_5:
            getResposta('bd01d218-69fd-11ef-9dd0-02bc37d68b7b') +
            '\n' +
            getResposta('e783793a-69fd-11ef-9dd0-02bc37d68b7b'),
          others: anamneseCopy.respostas,
        }),
      );

      fetch(`https://${process.env.REACT_APP_API_URL}/public/v1/pdf/generate`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(pdfBlob);
          link.download = 'anamnese.pdf';
          link.click();
        })
        .finally(() => setIsLoading(false));
    } catch (error) {
      setIsLoading(false);
    }
  };

  const isUrlValida = (url) => {
    try {
      const urlObj = new URL(url);
      return urlObj.protocol === 'http:' || urlObj.protocol === 'https:';
    } catch (e) {
      return false;
    }
  };

  return (
    <Grid id="container" container>
      <Toolbar />
      <Grid container id="content" sx={{position: 'relative'}}>
        <IconButton
          id="icon-primary"
          title={'Imprimir'}
          onClick={handleConvert}
          sx={styles.printButton}>
          {isLoading ? (
            <CircularProgress size={24} color="black" />
          ) : (
            <PrintOutlined />
          )}
        </IconButton>
        <Box ref={componentRef}>
          <Grid>
            <Grid
              item
              xs={12}
              className="display-block"
              sx={styles.reportHeader}>
              <Grid container item xs={6}>
                <IconeLogo style={styles.headerIcon} />
                <Typography sx={styles.headerTitle}>Wolf Pro Link</Typography>
              </Grid>
              <Grid container item xs={6}>
                <Typography sx={styles.title}>Avaliação dos atletas</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Atleta atleta={anamnese.atleta || {}} />
          <Grid container item alignContent="flex-start" rowGap={2} padding={2}>
            {grupos.map((g) => (
              <Grid container>
                <Typography sx={styles.groupTitle}>{g.label}</Typography>
                {respostas
                  ?.filter(
                    (r) =>
                      r.modeloPergunta?.pergunta?.modeloGrupo?.id === g.value,
                  )
                  ?.map((r) => {
                    const pergunta = r.modeloPergunta.pergunta;
                    let resposta = formData[r.id];
                    let respostaCor = '#fff';
                    if (pergunta.tipo === 'COMBOBOX') {
                      respostaCor = pergunta.valoresReferencia.filter(
                        (v) => v.id === resposta,
                      )?.[0]?.corHex;
                      resposta = pergunta.valoresReferencia.filter(
                        (v) => v.id === resposta,
                      )?.[0]?.nome;
                    } else if (pergunta.tipo === 'DATE') {
                      resposta = Data.dataFormat(resposta);
                    }
                    return (
                      <Grid
                        key={r.modeloPergunta?.id}
                        container
                        item
                        xs={12}
                        columnSpacing={2}
                        marginY={1}
                        alignItems="flex-end">
                        <Grid item xs={12}>
                          <Input
                            label={pergunta.pergunta}
                            type="text"
                            value={resposta}
                            className="input"
                            sx={[
                              styles.input,
                              {
                                '.MuiOutlinedInput-input': {
                                  borderRadius: '8px',
                                  backgroundColor: `${respostaCor} !important`,
                                },
                              },
                            ]}
                            disabled
                          />
                        </Grid>
                        {pergunta.urlReferencia &&
                          isUrlValida(pergunta.urlReferencia) && (
                            <Grid item xs={12} className="display-none">
                              <Link
                                href={pergunta.urlReferencia}
                                target="_blank"
                                sx={{color: '#fff', fontSize: 12}}>
                                Link de referência
                              </Link>
                            </Grid>
                          )}
                      </Grid>
                    );
                  })}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

const Atleta = ({atleta}) => {
  const getStringEndereco = () => {
    if (!atleta.enderecos?.length) return '';
    const endereco =
      atleta.enderecos?.filter((e) => e.principal)?.[0] ||
      atleta.enderecos?.[0];

    return `${endereco?.cidade?.nome} - ${endereco?.cidade?.uf}`;
  };

  return (
    <Grid className="atleta-header" container sx={styles.content} padding={2}>
      <Grid item md={6} xs={12}>
        <Typography sx={styles.title}>{atleta.nome}</Typography>
        <Typography sx={styles.text}>{`${Data.dataFormat(
          atleta.dataNascimento,
        )}`}</Typography>
        {!!atleta.enderecos?.length && (
          <Typography sx={styles.text}>{getStringEndereco(atleta)}</Typography>
        )}
        <Typography sx={styles.text}>
          {String.formatTelefone(atleta.telefone)}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography sx={styles.title}>Dados básicos</Typography>
        <Typography sx={styles.text}>{atleta.modalidade?.descricao}</Typography>
        <Typography sx={styles.text}>
          {atleta.funcoes?.map((f) => f.descricao).join(' | ')}
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = {
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 14,
    color: '#031663',
  },
  reportHeader: {
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  content: {
    maxWidth: 1200,
    alignItems: 'flex-start',
    borderBottom: '1px solid #fff',

    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
    },
  },
  title: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
  },
  text: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',
  },
  input: {
    width: '100%',
    '.Mui-disabled': {
      color: '#000 !important',
      '-webkit-text-fill-color': '#000 !important',
    },
  },
  groupTitle: {
    color: 'var(--primary)',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
  },
  printButton: {
    position: 'absolute',
    top: '30px',
    right: '16px',
  },
  headerTitle: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '32px',
    fontWeight: 900,
  },
  headerIcon: {
    height: '40px',
    width: '40px',
    marginRight: '18px',
  },
};
